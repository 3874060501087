import {
  Navigate,
  RouteObject,
  RouterProvider,
  createBrowserRouter,
  isRouteErrorResponse,
  useLocation,
  useRouteError,
} from 'react-router-dom'
import { AUTH_ROUTES } from './auth/auth.route'
import { FC, useMemo } from 'react'
import { PARENT_HOMEPAGE, PARENT_ROUTES } from './parent/parent.router'
import { TEACHER_HOMEPAGE, TEACHER_ROUTES } from './teacher/teacher.router'
import { useAuthContext } from '@/lib/auth/auth-provider'
import NoPermissionState from '@features/states/no-permisson-state'
import FullPageLoaderTemplate from '@templates/full-page-loader.template'
import env from '@/config/env'
import { ADMIN_HOMEPAGE, ADMIN_ROUTES } from './admin/admin.router'

import * as Sentry from '@sentry/react'
import ClientErrorState from '@features/states/client-error-state'
import { Component as RootLayout } from './layout'
import { USER_ROLE } from '@/core/constant/roles'

const router = (isLogged: boolean, role?: USER_ROLE | null) => {
  // console.log("isLogged",isLogged);

  let routes: RouteObject = {
    path: '',
    lazy: () => import('./layout'),
    // element: <RootLayout />,
    children: [],
  }

  // Logged In & Role is parent
  if (isLogged && role === USER_ROLE.GUARDIAN) {
    routes?.children?.push({
      index: true,
      element: <Navigate replace to={PARENT_HOMEPAGE} />,
    })
    routes?.children?.push({
      path: '',
      ...PARENT_ROUTES,
    })
    routes?.children?.push({
      path: '*',
      element: <Navigate replace to={PARENT_HOMEPAGE} />,
    })
  }
  // Logged In & Role is teacher
  if (isLogged && role === USER_ROLE.TEACHER) {
    routes?.children?.push({
      index: true,
      element: <Navigate replace to={TEACHER_HOMEPAGE} />,
    })
    routes?.children?.push({
      path: '/teacher',
      ...TEACHER_ROUTES,
    })
    routes?.children?.push({
      path: '*',
      element: <Navigate replace to={TEACHER_HOMEPAGE} />,
    })
  }
  // Logged In & Role is admin
  if (isLogged && role === USER_ROLE.ADMIN) {
    routes?.children?.push({
      index: true,
      element: <Navigate replace to={ADMIN_HOMEPAGE} />,
    })
    routes?.children?.push({
      path: '/admin',
      ...ADMIN_ROUTES,
    })
    routes?.children?.push({
      path: '*',
      element: <Navigate replace to={ADMIN_HOMEPAGE} />,
    })
  }
  // Logged In & Role invalid
  if (isLogged && role === null) {
    routes?.children?.push({
      index: true,
      element: <NoPermissionState />,
    })
    routes?.children?.push({
      path: '*',
      element: <NoPermissionState />,
    })
  }
  // Logged In & Watting for role
  if (isLogged && role === undefined) {
    routes?.children?.push({
      path: '*',
      element: null,
    })
  }
  // Not logged In
  if (!isLogged) {
    routes?.children?.push({
      index: true,
      element: <Navigate replace to={'/auth/login'} />,
    })
    routes?.children?.push({
      path: 'auth',
      ...AUTH_ROUTES,
    })
    routes?.children?.push({
      path: '*',
      element: <Navigate replace to={'/auth/login'} />,
    })
  }

  return createBrowserRouter(
    [
      {
        path: '',
        errorElement: <GlobalError />,
        children: [{ ...routes }],
      },
    ],
    {
      basename: `/${env.BASE_NAME}`,
    },
  )
}

export type GlobalErrorProps = {
  className?: string
}

export const GlobalError: FC<GlobalErrorProps> = () => {
  const error = useRouteError()

  if (import.meta.env.MODE !== 'development' && error) {
    console.log(error)
    Sentry.captureException(error)
  }
  console.log(error)

  return <ClientErrorState />
}

const RootRouter = () => {
  const { currentUser, isLoading, userRole, userInfo } = useAuthContext()
  // console.log(currentUser,userRole);

  const ROUTES = useMemo(
    () => router(!!currentUser, userRole),
    [currentUser, userRole],
  )

  if (isLoading) return <FullPageLoaderTemplate />
  return <RouterProvider key={currentUser} router={ROUTES} />
}

export default RootRouter
