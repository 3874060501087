export enum USER_ROLE {
  GUARDIAN = 'Parent',
  TEACHER = 'Teacher',
  ADMIN = 'Admin',
}

export enum SIS_ROLES {
  AdmissionManager = 'SIS Admission Manager',
  AttendanceManager = 'SIS Attendance Manager',
  ContentCreator = 'SIS Content Creator',
}
