import { Navigate, RouteObject } from 'react-router-dom'

export const ADMIN_HOMEPAGE = '/admin/news'
export const ADMIN_ROUTES: RouteObject = {
  path: 'admin',
  lazy: () => import('./admin.layout'),
  children: [
    {
      index: true,
      element: <Navigate replace to={ADMIN_HOMEPAGE} />,
    },
    {
      path: 'news',
      // lazy: () => import("./feed/feed.page"),
      children: [
        {
          path: '',
          lazy: () => import('./news/news.page'),
        },
        {
          path: ':id',
          lazy: () => import('./news/news-detail/news-detail.page'),
        },
      ],
    },
    {
      path: 'activities',
      // lazy: () => import("./feed/feed.page"),
      children: [
        {
          path: '',
          lazy: () => import('./activities/activities.page'),
        },
        {
          path: ':id',
          lazy: () => import('./activities/page-detail/page-detail.page'),
        },
      ],
    },
    {
      path: 'policy-documents',
      children: [
        {
          path: '',
          lazy: () => import('./policy-documents/policy-documents.page'),
        },
        {
          path: ':id',
          lazy: () =>
            import('./policy-documents/document-detail/document-detail.page'),
        },
      ],
    },
    {
      path: 'timetable',
      lazy: () => import('./timetable/timetable.page'),
    },
    {
      path: 'menu',
      children: [
        {
          path: '',
          lazy: () => import('./menu/menu.page'),
        },
        {
          path: ':id',
          lazy: () => import('./menu/menu-detail/menu-detail.page'),
        },
      ],
    },
    {
      path: 'attendance',
      // lazy: () => import("./feed/feed.page"),
      children: [
        {
          path: '',
          element: <Navigate replace to="/admin/attendance/classes" />,
        },
        {
          path: 'classes',
          lazy: () =>
            import('./attendance/attendance-classes/attendance-classes.page'),
        },
        {
          path: 'classes/new-record',
          lazy: () =>
            import(
              './attendance/attendance-class-record-detail/create-record-detail.page'
            ),
        },
        {
          path: 'classes/:id',
          lazy: () =>
            import(
              './attendance/attendance-class-record-detail/update-record-detail.page'
            ),
        },
      ],
    },
    {
      path: 'settings',
      lazy: () => import('./settings/settings.page'),
    },
  ],
}
