import { useAcceptTerms } from '@/api/account/use-accept-terms'
import { useGetUserInfo } from '@/api/account/use-user-info'
import { FRAPPE_APIS } from '@/api/api.config'
import { USER_ROLE } from '@/core/constant/roles'
import { PPUser } from '@/types/ParentPortal/PPUser'
import { SISPerson } from '@/types/SIS/SISPerson'
import {
  useFrappeAuth,
  useFrappeGetCall,
  useFrappePostCall,
  useFrappePutCall,
  useSWRConfig,
} from 'frappe-react-sdk'
import React, { createContext, useState } from 'react'

interface AuthContextProps {
  isLoading?: boolean
  isRequesting?: boolean
  currentUser?: string | null
  userInfo?: SISPerson | null
  isAgreedTerms?: boolean
  login: (username: string, password: string) => Promise<void>
  logout: () => Promise<void>
  updateCurrentUser: VoidFunction
  updateUserInfo: VoidFunction
  resetPassword: (email: string, send_email: boolean) => void
  updatePassword: (key: string, newPassword: string) => void
  changePassword: (oldPassword: string, newPassword: string) => void
  acceptTerms: () => Promise<void>
  userRole?: USER_ROLE | null
  userSISRoles?: string[]
  prefixRoute?: string
}

export const AuthContext = createContext<AuthContextProps>({
  currentUser: '',
  isAgreedTerms: false,
  isLoading: false,
  isRequesting: false,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  updateCurrentUser: () => {},
  updateUserInfo: () => {},
  resetPassword: () => {},
  updatePassword: () => {},
  changePassword: () => {},
  acceptTerms: () => Promise.resolve(),
  prefixRoute: '',
})

export const useAuthContext = () => React.useContext(AuthContext)

export const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const { mutate } = useSWRConfig()
  const {
    login,
    logout,
    currentUser,
    updateCurrentUser,
    isLoading: authLoading,
  } = useFrappeAuth()

  const [userInfo, setUserInfo] = useState<AuthContextProps['userInfo']>()
  const [userRole, setUserRole] = useState<AuthContextProps['userRole']>()
  const [userSISRoles, setUserSISRoles] = useState<
    AuthContextProps['userSISRoles']
  >([])
  const [prefixRoute, setPrefixRoute] = useState<string>('')
  const [isAgreedTerms, setIsAgreedTerms] =
    useState<AuthContextProps['isAgreedTerms']>()

  const { call: resetPwd, loading: resetPwdLoading } = useFrappePostCall(
    FRAPPE_APIS.RESET_PASSWORD.METHOD_STRING,
  )
  const { call: updatePwd, loading: updatePwdLoading } = useFrappePostCall(
    FRAPPE_APIS.UPDATE_PASSWORD.METHOD_STRING,
  )
  const { handleSubmit: acceptTermsAndConditions, loading: acceptLoading } =
    useAcceptTerms()

  const { isLoading: getUserInfoLoading, mutate: mutateUserInfo } =
    useGetUserInfo(!currentUser, {
      revalidateOnFocus: false,
      onSuccess: (data) => {
        console.log('User Info:', data)

        const primaryRole = data.message.sis_role
        const sisRoles = data.message.assigned_sis_roles || []
        const userInfo = data.message.person
        const isAgreed = data.message.agreed_terms_and_conditions
        setIsAgreedTerms(isAgreed === 1)
        setUserInfo(userInfo)
        setUserSISRoles(sisRoles)

        switch (primaryRole) {
          case USER_ROLE.GUARDIAN:
            setPrefixRoute('')
            break
          case USER_ROLE.TEACHER:
            setPrefixRoute('teacher')
            break
          case USER_ROLE.ADMIN:
            setPrefixRoute('admin')
            break
          default:
            setUserRole(null)
            return
        }
        setUserRole(primaryRole)
      },
      onError: () => {
        setUserRole(null)
      },
    })

  const isLoading = authLoading || getUserInfoLoading

  const isRequesting = resetPwdLoading || updatePwdLoading || acceptLoading

  const handleResetPassword = async (email: string, send_email: boolean) => {
    try {
      await resetPwd({ email, send_email })
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const handleUpdatePassword = async (key: string, newPassword: string) => {
    try {
      await updatePwd({ key, new_password: newPassword })
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const handleChangePassword = async (
    oldPassword: string,
    newPassword: string,
  ) => {
    try {
      await updatePwd({ old_password: oldPassword, new_password: newPassword })
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const handleLogout = async () => {
    try {
      localStorage.removeItem('ppLastChannel')
      await logout()
      updateCurrentUser()
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const handleLogin = async (username: string, password: string) => {
    try {
      await login({
        username,
        password,
      })
      updateCurrentUser()
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const handleAcceptTerms = async () => {
    try {
      const result = await acceptTermsAndConditions()
      mutateUserInfo()
      return Promise.resolve(result.message)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        userInfo,
        currentUser: currentUser || null,
        updateCurrentUser,
        updateUserInfo: mutateUserInfo,
        login: handleLogin,
        logout: handleLogout,
        resetPassword: handleResetPassword,
        updatePassword: handleUpdatePassword,
        changePassword: handleChangePassword,
        acceptTerms: handleAcceptTerms,
        userRole,
        userSISRoles,
        prefixRoute,
        isAgreedTerms,
        isLoading,
        isRequesting,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
